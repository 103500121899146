import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const linkStyle = {
    textDecoration: "none",
    color: "grey",
    fontWeight: 800,
}
const hrStyle = {
    margin: "50px auto"
}
const SchoolPage = () => {
    return (

        <Layout>
            <Seo title="Education"/>
            <div align="center">
                <h1>Education</h1>
                <p>横浜・センター北にプログラミングスクールを開校予定です。</p>
                <p>事前申し込み・お問い合わせは<Link
                    to="https://docs.google.com/forms/d/e/1FAIpQLSfz1OeCBACBsngV45Hqamk6_osbbvRjFMQR955kDZAldrynaA/viewform"
                    style={linkStyle} rel="noreferrer noopener" target="_blank">こちら</Link>からご連絡ください。</p>
                <div className="cardListGrid">
                    <div className="card card-skin">
                        <div className="card__imgframe">
                            <StaticImage
                                src="../images/gatsby-icon.png"
                            />
                        </div>
                        <div className="card__textbox">
                            <div className="card__titletext">
                                Scratchを通じて学ぶ
                            </div>
                            <div className="card__overviewtext">
                                プログラミングに必要な論理的思考能力をScratchを通して学びます。
                            </div>
                        </div>
                    </div>

                    <div className="card card-skin">
                        <div className="card__imgframe">
                            <StaticImage
                                src="../images/gatsby-icon.png"
                            />
                        </div>
                        <div className="card__textbox">
                            <div className="card__titletext">
                                自分で考え、やり抜く力を鍛える
                            </div>
                            <div className="card__overviewtext">
                                教えられた事を進めていくだけでなく、課題をとおして自己解決能力、想像略を養います。
                            </div>
                        </div>
                    </div>

                    <div className="card card-skin">
                        <div className="card__imgframe">
                            <StaticImage
                                src="../images/gatsby-icon.png"
                            />
                        </div>
                        <div className="card__textbox">
                            <div className="card__titletext">
                                チームワークを育む
                            </div>
                            <div className="card__overviewtext">
                                チームで取り組み、ともに問題を解決します。
                            </div>
                        </div>
                    </div>
                </div>

                <hr style={hrStyle}/>

                <h2>料金表</h2>
                    <table>
                        <tr>
                            <td>週１コース</td>
                            <td>3000円</td>
                        </tr>
                        <tr>
                            <td>週２コース</td>
                            <td>5000円</td>
                        </tr>
                        <tr>
                            <td>週４コース</td>
                            <td>9000円</td>
                        </tr>
                        <tr>
                            <td>追加レッスン</td>
                            <td>3000円</td>
                        </tr>
                        <tr>
                            <td>PCレンタル</td>
                            <td>3000円</td>
                        </tr>
                        <tr>
                            <td>自習利用オプション</td>
                            <td>5000円</td>
                        </tr>
                    </table>
                    <p align="right">※価格は税抜き表示になります。</p>
            </div>
        </Layout>
)
}

export default SchoolPage
